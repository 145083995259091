import { createContext, useContext, useCallback } from "react"
import { usePersistedState } from "hooks"

const defaultValue = {
  token: null,
  me: null,
  isLogin: false,
  logout: () => {},
  setAuthToken: () => {},
  setAuthUser: () => {},

}

const AuthContext = createContext(defaultValue)

export const AuthProvider = ({ children }) => {

  const [token, setToken] = usePersistedState(
    "cellpay_token",
    localStorage.getItem("cellpay_token")
  );
  const [me, setMe] = usePersistedState("me", localStorage.getItem("me"));
  
  const logout = useCallback(() => {
    setMe(null);
    setToken(null);
  }, [setMe, setToken])

  const setAuthToken = useCallback((token) => {
    setToken(token)
  }, [setToken])

  const setAuthUser = useCallback((user) => {
    setMe(JSON.stringify(user))  
  }, [setMe])

  return (
    <AuthContext.Provider
      value={{
        token,
        isLogin: !(token === null || token === 'null' || token === ''),
        me: me ? JSON.parse(me) : null,
        logout,
        setAuthToken,
        setAuthUser,

      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext)
