import { connect } from 'react-redux'
import Dashboard from 'pages/dashboard'
import { getDashboardData } from 'actions'

const mapStateToProps = (state) => {
   return {
      ...state.dashboardReducer
   }
}

const mapActionsToProps = {
   getDashboardData,
}

export default connect(mapStateToProps, mapActionsToProps)(Dashboard)