import { memo } from 'react'
import Header from "containers/headerContainer"
import Main from "./components/main"

const Layout = (props) => {

	const { children } = props

	return (
		<>
			<div>
				<Header />

				<Main className="py-4">
		      		{ children }
		      	</Main>
			</div>
		</>
	)
}

export default memo(Layout)