import { memo } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Placeholder from 'react-bootstrap/Placeholder'

const DashboardSkeleton = (props) => {

	return (
		<>
			<Row className="mb-3">
				<Col md={12}>
					<Card>
						<Placeholder as={Card.Header} animation="glow">
							<Placeholder xs={3} />
						</Placeholder>
						
						<Placeholder as={Card.Body} animation="glow">
							{Array.from({ length: 10 }, (_, index) =>
								<Placeholder key={index} as="p" animation="glow">
									<Placeholder xs={12} />
								</Placeholder>
							)}
						</Placeholder>
					</Card>	
				</Col>
			</Row>

			<Row className="mb-3">
				<Col md={12}>
					<Card>
						<Placeholder as={Card.Header} animation="glow">
							<Placeholder xs={3} />
						</Placeholder>
						
						<Placeholder as={Card.Body} animation="glow">
							{Array.from({ length: 10 }, (_, index) =>
								<Placeholder key={index} as="p" animation="glow">
									<Placeholder xs={12} />
								</Placeholder>
							)}
						</Placeholder>
					</Card>	
				</Col>
			</Row>
		</>
	)
}

export default memo(DashboardSkeleton)