import { combineReducers } from 'redux'
import dashboardReducer from './dashboardReducer'

const initialState = {
	app_name: 'Cellpay',
	show_loader: false,
	authenticated: !(localStorage.getItem('cellpay_token') === null || localStorage.getItem('cellpay_token') === 'null' || localStorage.getItem('cellpay_token') === ''),
	user: {},
}

const reducer = (state = initialState, action) => {
	switch(action.type) {
		
		case 'SHOW_LOADER':
			return {
				...state,
				show_loader: true,
			}

		case 'HIDE_LOADER':
			return {
				...state,
				show_loader: false,
			}

		case 'USER_LOGGED':
			
			return {
				...state,
				authenticated: true,
				user: action.payload.user,
			}

		case 'USER_LOGGED_OUT':
			return {
				...state,
				authenticated: false,
			}

		case 'UPDATE_LOGGED_USER':
			
			return {
				...state,
				user: action.payload.user,
			}

		case 'UPDATE_SELECTED_ACCOUNT':
			return {
				...state,
				default_account: action.payload.default_account,
			}

		case 'USER_PROFILE_UPDATE':
			return {
				...state,
				user: { ...action.payload.user }
			}

		default:
			return state
	}
}

const rootReducer = combineReducers({reducer, dashboardReducer })

export default rootReducer