import { memo, useEffect, useCallback } from 'react'
import { useNavigate } from "react-router-dom"

export const PrivateRoute = ({ children }) => {
  const navigate = useNavigate()

  const redirectLogin = useCallback(() => {
    navigate("/login")
  }, [navigate])

  useEffect( () => {
    if(localStorage.getItem('cellpay_token') === null || localStorage.getItem('cellpay_token') === 'null' || localStorage.getItem('cellpay_token') === '') {
      redirectLogin()
    }
  }, [redirectLogin])

  // return !(localStorage.getItem('cellpay_token') === null || localStorage.getItem('cellpay_token') === 'null' || localStorage.getItem('cellpay_token') === '') ? children : redirectLogin()

  return (
    <>
      { children }
    </>
  )
}

export default memo(PrivateRoute)