import axios from 'axios'
import Cookies from 'js-cookie'

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/*try {
    require('bootstrap');
} catch (e) {
    console.log(`Error Occured in loading bootstrap ${e}`)
}*/

// window.axios = require('axios')
// window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
// window.axios.defaults.headers.common['X-CSRFToken'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

// axios.defaults.baseURL = '/reporting/'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
if(!(localStorage.getItem('cellpay_token') === null || localStorage.getItem('cellpay_token') === 'null' || localStorage.getItem('cellpay_token') === '')) {
    axios.defaults.headers.common['Authorization'] = `Token ${localStorage.getItem('cellpay_token')}`
}

// alert(Cookies.get('csrftoken'))
axios.defaults.headers.common['X-CSRFToken'] = Cookies.get('csrftoken')