const initialState = {
	// carriers_data: [],
	carriers_data: {},
	sales_chart: [],
	past_carriers_data: [],

}

const dashboardReducer = (state=initialState, action) => {
	switch(action.type) {
		case 'UPDATE_DASHBOARD_DATA':
			return {
				...state,
				carriers_data: [...action.payload.carriers_data],
				sales_chart: [...action.payload.sales_chart],
				// carriers_data: {...state.carriers_data, 'result': action.payload.carriers_data, 'count': action.payload.carriers_data.length},
				past_carriers_data: [...action.payload.past_carriers_data],
			}

		default:
			return { ...state }

	}
}

export default dashboardReducer