import DashboardService from 'services/dashboardService'

const getDashboardData = (params) => async(dispatch) => {
   try {
      
      const response = await DashboardService.getDashboardData(params)

      if(response.data.success) {
         dispatch({
            type: 'UPDATE_DASHBOARD_DATA',
            payload: response.data,
         })
      }
      return response

   } catch(err) {
      console.log(err)
   }  
}

export {
	getDashboardData,
}