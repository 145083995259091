import AuthService from 'services/authService'
import axios from 'axios'

export const submitLogin = (payload) => async(dispatch) => {
   try {
      
      const response = await AuthService.login(payload)
      
      if(response.data.token) {
         
         localStorage.setItem('cellpay_token', response.data.token)
         axios.defaults.headers.common['Authorization'] = `Token ${response.data.token}`
          
         dispatch({
            type: 'USER_LOGGED',
            payload: response.data
         })
      }

      return response

   } catch(err) {
      /*if(err.response.data.non_field_errors) {
         throw new Error(err.response.data.non_field_errors[0])
      }*/

      throw err
   }
}


export const submitLogout = () => async(dispatch) => {
   try {
      
      const response = await AuthService.logout()
      
      if(response.data.success) {
         localStorage.setItem('cellpay_token', null)
         localStorage.setItem('me', null)
         axios.defaults.headers.common['Authorization'] = ''

         dispatch({type: 'USER_LOGGED_OUT'})
      }

      return response

   } catch(error) {
      dispatch({type: 'HIDE_LOADER'})
   }
}

export const fetchUser = () => async(dispatch) => {
   try {
      
      const response = await AuthService.user()
      
      if(response.data.success) {
         dispatch({type: 'UPDATE_LOGGED_USER', payload: response.data})
      }
      return response

   } catch(error) {
      // dispatch({type: 'HIDE_LOADER'})
   }
}